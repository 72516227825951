import styled from "styled-components"

const LatestStyles = styled("header")`
  .title {
    text-align: center;
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 35px;
    @media(max-width: 700px) {
      width: 80%;
      margin: 0 auto;
      padding: 20px;
    }
  }
  .imageOne {
    display: flex;
    justify-content: center;
    transform: translateX(-20px);
  }
  .image {
    width: 50%;
    @media(max-width: 700px) {
      width: 70%;
    }
  }
  .social-share {
    display: flex;
    flex-direction: column;
    transform: translateX(-20px);
    @media(max-width: 500px) {
    transform: translateX(0);
      
    }
  }

  .body {
    margin: 0 auto;
    width: 50%;
    padding-top: 40px;
    @media(max-width: 700px) {
      width: 80%;
    }
  }
`

export default LatestStyles


